<template lang="pug">
include ../../../../configs/template
form.row
  div.col-12
    +select-validation('body.additional_info.type_document','listTypeDocument','typeDoc','nameLang','["required"]')(
    @input="getListDiplom"
    :readonly="isCheckDocument"
  )
      template(#item="data")
        div(:class="{ 'red-option': data.item.is_disable }").text-center.w-100 {{data.item[nameLang]}}
  div.col-12(v-if="requiredField.proof.includes(body.additional_info.type_document)")
    +select-validation('body.additional_info.diploma','listDiplomas','diploma','nameLang','["required"]')(
      @input="mappingFunctionByPosition(body.additional_info.diploma.list_positions)"
      return-object
      :readonly="isCheckDocument")
  div.col-12(v-if="isCheckDocument && body.additional_info.diploma").text-left
      b.w-100.px-0 {{ $t('limitation') }}:
      div(v-for="(func, index) of body.additional_info.function_limitation" :key="func.id").w-100.px-0 {{ qualificationLimitationFunctionByID(func.id_func).function[nameLang]  }}:
        +select('body.additional_info.function_limitation[index].id_limit', 'listPosition', '', 'nameLang', '[]')(
          v-if="qualificationLimitationFunctionByID(func.id_func).function.name_ukr !== 'немає'"
          multiple item-value="id")
  div.col-12(v-if="!requiredField.proof.includes(body.additional_info.type_document)")
    +field-validation('body.additional_info.other_number', 'number', '["required"]')(:readonly="isCheckDocument")
  div.col-12(v-if="isCheckDocument && !requiredField.proof.includes(body.additional_info.type_document)")
    +select-validation('body.additional_info.country','listCountry','country','"value"','["required"]')
  div(v-if="isCheckDocument").col-12
    +select-validation('body.additional_info.port', 'listPort', 'port', 'nameLang', '["required"]')
  div.col-12(v-if="isCheckDocument && !requiredField.proof.includes(body.additional_info.type_document)")
    +select-validation('body.additional_info.rank','filteredRanksList','rank','nameLang','["required"]')(@input="body.additional_info.list_positions = []")
  div.col-12(v-if="isCheckDocument && !requiredField.proof.includes(body.additional_info.type_document)")
    +select-validation('body.additional_info.list_positions','mappingPositions(body.additional_info.rank)','position','nameLang','["required"]')(multiple)
      template(#item="data")
        div(:class="{ 'red-option': data.item.is_disable }").text-center.w-100 {{data.item[nameLang]}}
  div.col-12
    +date-picker-validation('body.additional_info.date_start','dateIssue','dateObjectStart','["required", "minValue", "maxValue"]', 'isCheckDocument', '!isCheckDocument')(
    :readonly="isCheckDocument"
    )
  div.col-12(v-if="isCheckDocument && requiredField.date_end.includes(body.additional_info.type_document)")
    +date-picker-validation('body.additional_info.date_end','dateTermination','dateObjectEnd','["required", "minValue"]')
  div(v-if="isCheckDocument").col-12
    +field-validation('body.additional_info.strict_blank', 'strictBlank', '["required"]')(v-mask="'NNN######'")
  div(v-if="isCheckDocument").col-12
    FileDropZone(ref="mediaContent")
  div.col-12
    v-btn(v-if="isCheckDocument" color="success" @click="saveDocument" :loading="isLoadingSave").mr-4 {{$t('save')}}
    v-btn(v-else color="success" @click="checkDocument" :loading="isLoading") {{$t('btn.checkDocument')}}
    v-btn(v-if="isCheckDocument" color="secondary" @click="clearForm" :loading="isLoading") {{$t('btn.clear')}}

</template>

<script>
import { verificationSC } from '@/mixins/validationRules'
import { mapActions, mapGetters, mapState } from 'vuex'
import { mappingPositions, clearBody } from '@/mixins/main'
import { TYPE_DOCUMENTS_CONSTANTS } from '@/configs/constants'

const initBody = () => (
  {
    additional_info: {
      country: null,
      port: null,
      other_number: null,
      list_positions: null,
      date_start: null,
      date_end: null,
      type_document: null,
      diploma: null,
      statement: null,
      function_limitation: [],
      new_document: false,
      strict_blank: null,
      rank: null
    }
  }
)

export default {
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue')
  },
  props: {
    sailorId: { type: Number, default: null },
    typeDocument: { type: Number, default: null }
  },
  data () {
    return {
      mappingPositions,
      body: initBody(),
      requiredField: {
        proof: [16],
        date_end: [16, 21, 57, 85, 86, 88, 89]
      },
      listPositionFunction: [],
      isCheckDocument: false,
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      listQualificationType: state => state.directory.typeDocQualification,
      listCountry: state => state.directory.country,
      listRank: state => state.directory.ranks.filter(el => !el.is_disable),
      listPosition: state => state.directory.positionLimitation,
      positionFunction: state => state.directory.positionFunction,
      listDiplomas: state => state.sailor.diplomas,
      nameLang: state => state.main.lang === 'en' ? 'nameLang' : 'name_ukr',
      listPort: state => state.directory.ports.filter(port => !port.is_disable),
      isLoadingSave: state => state.documents.isLoading
    }),
    ...mapGetters(['qualificationLimitationFunctionByID']),
    dateObjectStart () {
      return this.body.additional_info.date_start ? new Date(this.body.additional_info.date_start) : null
    },
    dateObjectEnd () {
      return this.body.additional_info.date_end ? new Date(this.body.additional_info.date_end) : null
    },
    listPositionForDocument () {
      return this.body.additional_info.rank ? this.listPosition.filter(pos => pos.rank === this.body.additional_info.rank) : []
    },
    filteredRanksList () { // Allow only ranks that are available for the selected document type
      return this.listRank.filter(item => item.type_document === this.body.additional_info.type_document)
    },
    listTypeDocument () {
      return this.listQualificationType.filter(el => {
        if (this.typeDocument === TYPE_DOCUMENTS_CONSTANTS.PROOF_OF_DIPLOMA) {
          return this.requiredField.proof.includes(el.id)
        } else {
          return !this.requiredField.proof.includes(el.id)
        }
      })
    }
  },
  validations () { return verificationSC.qualification(this) },
  methods: {
    ...mapActions(['getDiplomas', 'getInfoAboutDocumentForDigitizationSC']),
    getListDiplom (item) {
      if (item === 16) this.getDiplomas(this.sailorId)
    },
    saveDocument () {
      const { statementId } = this.$route.params
      let cloneBody = { ...this.body }
      if (this.$v.$invalid) return this.$v.$touch()
      cloneBody.additional_info = { ...clearBody({ ...cloneBody.additional_info }) }
      if (!cloneBody?.additional_info.diploma) delete cloneBody.additional_info.function_limitation
      let data = {
        body: { ...cloneBody },
        media: {
          name: (this.requiredField.proof.includes(cloneBody.additional_info.type_document) ? 'ProofOfWorkDiploma' : 'QualificationDoc'),
          files: [...this.$refs.mediaContent.filesArray]
        }
      }
      this.requiredField.proof.includes(cloneBody.additional_info.type_document) && (data.body.type_override = 6)
      this.$emit('saveDocument', { id: statementId, data, method: 'setDigitizationDocumentSC' })
    },
    mappingFunctionByPosition (item) {
      if (item && !Array.isArray(item)) this.body.additional_info.function_limitation = this.positionFunction.filter(el => el.id === item.id).map(el => ({ id_func: el.id, id_limit: [] }))
      else if (item && Array.isArray(item)) {
        this.body.additional_info.function_limitation = this.positionFunction.filter(el => {
          return el.position === item[0].id
        }).map(el => ({ id_func: el.id, id_limit: [] }))
      } else this.body.additional_info.function_limitation = []
    },
    async checkDocument () {
      let cloneBody = { ...this.body }
      if (this.$v.$invalid) return this.$v.$touch()
      if (cloneBody.additional_info?.diploma && Object.keys(cloneBody.additional_info?.diploma).length) {
        cloneBody.additional_info.diploma = cloneBody.additional_info.diploma.id
      }
      this.isLoading = true
      const { statementId } = this.$route.params
      const response = await this.getInfoAboutDocumentForDigitizationSC({ id: statementId, body: { additional_info: { ...clearBody(cloneBody.additional_info) } } })
      if (response) {
        this.$notification.success('notify.success.checkDocument')
        this.isCheckDocument = true
      }
      this.isLoading = false
    },
    clearForm () {
      this.isCheckDocument = false
      this.body = initBody()
      this.$v.$reset()
    }
  }
}
</script>
